















import { Component, Vue, Prop } from 'vue-property-decorator'
import PageHeader from '@/admin/components/PageHeader.vue'
import __ from '@/shared/helpers/__'
import GroupService from '@/shared/modules/group/group.service'
import { downloadFile } from '@/shared/helpers/download-file.helper'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import { GlobalActions } from '@/shared/store/global/global.actions'
import _ from 'lodash'
import moment from 'moment'

@Component({
  components: { PageHeader },
  methods: { __ }
})
export default class DownloadReport extends Vue {
  @Prop() meta: any
  withViolations: boolean = true
  loading: boolean = false


  downloadPdf() {
    this.loading = true
    GroupService.generatePdf(this.company, this.meta.group.projectUuid, this.meta.group, this.withViolations)
      .then((response: any) => {
        downloadFile(response, _.snakeCase(`${ this.company.name }_${ this.meta.group.name }_${ moment().format('YYYY-MM-DD HH:m') }`))
        this.loading = false
        this.$store.dispatch(GlobalActions.closeDialog)
      })
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
