import Service from '@/shared/classes/service'
import Group from '@/shared/modules/group/group.model'
import Company from '@/shared/modules/company/company.model'
import Project from '@/shared/modules/project/models/project.model'
import IResponse from '@/shared/interfaces/modules/response.interface'
import IGroupResponse from '@/shared/modules/group/group-response.interface'
import Attachment from '@/shared/modules/attachment/attachment.model'
import IAttachmentResponse from '@/shared/modules/attachment/attachment-response.interface'

export default class SystemicGroupService extends Service {
  static prefix = 'systemic-groups'

  static index(): Promise<Group[]> {
    return this.get(`${ this.prefix }`)
      .then((response: IResponse<IGroupResponse[]>) => response.data.map((item: IGroupResponse) => new Group(item)))
  }
}
