















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import PageHeader from '@/admin/components/PageHeader.vue'
import _ from 'lodash'
import __ from '@/shared/helpers/__'
import Form from '@/shared/components/form/Form.vue'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import { GlobalActions } from '@/shared/store/global/global.actions'
import FormBase from '@/shared/classes/form/form-base'
import Checklist from '@/shared/modules/checklist/checklist.model'
import IChecklistResponse from '@/shared/modules/checklist/checklist-response.interface'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import ObjectField from '@/shared/classes/form/fields/object-field'
import Field, { FieldSizes } from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import SearchableField from '@/shared/classes/form/fields/Select/searchable-field'
import { FilterOperators } from '@/shared/classes/components/data-table/data-table-filter'
import { GroupType } from '@/shared/modules/group/group.model'
import ArrayField from '@/shared/classes/form/fields/array-field'

@Component({
  components: { Form, PageHeader },
  methods: { __ }
})
export default class AddChecklistDialog extends Vue {
  @Prop() meta!: any
  @Watch('form.data', { deep: true }) private onFormDataChange() {
    const breadcrumb = this.form.data.breadcrumb
    const breadcrumbField: ObjectField | any = this.form.fields.find((field: Field) => field.key === 'breadcrumb')
    // const specialtyCheckedField = breadcrumbField.children.find((field: Field) => field.key === 'specialty_checked')
    const groupCheckedField = breadcrumbField.children.find((field: Field) => field.key === 'group_checked')
    const specialtySelectField = breadcrumbField.children.find((field: Field) => field.key === 'specialty_uuid')
    const groupSelectField = breadcrumbField.children.find((field: Field) => field.key === 'group_uuid')

    // if (breadcrumb.category_checked) {
    //   breadcrumb.specialty_checked = true
    //   breadcrumb.group_checked = true
    //   specialtyCheckedField.disabled = true
    //   groupCheckedField.disabled = true
    // } else {
    //   specialtyCheckedField.disabled = false
    //   groupCheckedField.disabled = false
    //
    //   if (breadcrumb.category_uuid) {
    //     specialtySelectField.loadItemsProps = {
    //       ...specialtySelectField.loadItemsProps,
    //       filters: [
    //         {
    //           type: FilterOperators.equals,
    //           name: 'parent_uuid',
    //           value: breadcrumb.category_uuid,
    //         },
    //         {
    //           type: FilterOperators.equals,
    //           name: 'type',
    //           value: GroupType.checklists,
    //         }
    //       ]
    //     }
    //     specialtySelectField.disabled = false
    //   } else {
    //     specialtySelectField.disabled = true
    //     groupSelectField.disabled = true
    //     breadcrumb.specialty_uuid = ''
    //     breadcrumb.group_uuid = ''
    //   }
    // }

    if (breadcrumb.specialty_checked) {
      breadcrumb.group_checked = true
      groupCheckedField.disabled = true

      groupSelectField.disabled = ! breadcrumb.specialty_uuid;
    } else {
      groupCheckedField.disabled = false

      if (breadcrumb.specialty_uuid) {
        groupSelectField.disabled = false
        groupSelectField.loadItemsProps = {
          ...specialtySelectField.loadItemsProps,
          filters: [
            {
              type: FilterOperators.equals,
              name: 'parent_uuid',
              value: breadcrumb.specialty_uuid,
            },
            {
              type: FilterOperators.equals,
              name: 'type',
              value: GroupType.checklists,
            }
          ]
        }
      } else {
        groupSelectField.disabled = true
        breadcrumb.group_uuid = ''
      }
    }
  }

  form: FormBase = new FormBase()
    .setModel(Checklist)
    .setEndpoint(`company/${ this.company.slug }/checklists`)
    .setTranslatable(true)
    .setFields([
      new ObjectField()
        .setKey('breadcrumb')
        .setTitle(__('company.components.checklists.add-checklist-dialog.form.fields.breadcrumb.title'))
        .setChildren([
          // new Field()
          //   .setType(FieldTypes.checkbox)
          //   .setKey('category_checked')
          //   .setTooltip(__('company.components.checklists.add-checklist-dialog.form.fields.breadcrumb.category-checked'))
          //   .setClasses('mt-3')
          //   .setSize(FieldSizes.oneTwelfth),
          // new SearchableField()
          //   .setKey('category_uuid')
          //   .setTitle(__('company.components.checklists.add-checklist-dialog.form.fields.breadcrumb.category'))
          //   .loadItems({
          //     endpoint: `company/${ this.company.slug }/projects/${ this.meta.project.uuid }/groups`,
          //     value: 'uuid',
          //     title: 'name',
          //     perPage: 20,
          //     params: {
          //       withoutChildren: true,
          //       withPagination: true
          //     },
          //     filters: [
          //       {
          //         type: FilterOperators.nulled,
          //         name: 'parent_uuid',
          //         value: true,
          //       },
          //       {
          //         type: FilterOperators.equals,
          //         name: 'type',
          //         value: GroupType.checklists,
          //       }
          //     ]
          //   })
          //   .setVisibleIf((values: any) => ! values.breadcrumb.category_checked)
          //   .setSize(FieldSizes.elevenTwelfth),
          // new Field()
          //   .setKey('category_name')
          //   .setTitle(__('company.components.checklists.add-checklist-dialog.form.fields.breadcrumb.category-name'))
          //   .setTranslatable(true)
          //   .setVisibleIf((values: any) => !! values.breadcrumb.category_checked)
          //   .setSize(FieldSizes.elevenTwelfth),
          new Field()
            .setType(FieldTypes.checkbox)
            .setKey('specialty_checked')
            .setTooltip(__('company.components.checklists.add-checklist-dialog.form.fields.breadcrumb.specialty-checked'))
            .setClasses('mt-3')
            .setSize(FieldSizes.oneTwelfth),
          new SearchableField()
            .setKey('specialty_uuid')
            .setTitle(__('company.components.checklists.add-checklist-dialog.form.fields.breadcrumb.specialty'))
            .loadItems({
              endpoint: `company/${ this.company.slug }/projects/${ this.meta.project.uuid }/groups`,
              value: 'uuid',
              title: 'name',
              perPage: 20,
              params: {
                withoutChildren: true,
                withPagination: true
              },
              filters: [
                {
                  type: FilterOperators.nulled,
                  name: 'parent_uuid',
                  value: true,
                },
                {
                  type: FilterOperators.equals,
                  name: 'type',
                  value: GroupType.checklists,
                }
              ]
            })
            .setVisibleIf((values: any) => ! values.breadcrumb.specialty_checked)
            .setSize(FieldSizes.elevenTwelfth)
            .isRequired(),
          new Field()
            .setKey('specialty_name')
            .setTitle(__('company.components.checklists.add-checklist-dialog.form.fields.breadcrumb.specialty-name'))
            .setTranslatable(true)
            .setVisibleIf((values: any) => !! values.breadcrumb.specialty_checked)
            .setSize(FieldSizes.elevenTwelfth)
            .isRequired(),
          new Field()
            .setType(FieldTypes.checkbox)
            .setKey('group_checked')
            .setTooltip(__('company.components.checklists.add-checklist-dialog.form.fields.breadcrumb.group-checked'))
            .setClasses('mt-3')
            .setSize(FieldSizes.oneTwelfth),
          new SearchableField()
            .setKey('group_uuid')
            .setTitle(__('company.components.checklists.add-checklist-dialog.form.fields.breadcrumb.group'))
            .loadItems({ endpoint: `company/${ this.company.slug }/projects/${ this.meta.project.uuid }/groups`, value: 'uuid', title: 'name', perPage: 20 })
            .setVisibleIf((values: any) => ! values.breadcrumb.group_checked)
            .setSize(FieldSizes.elevenTwelfth)
            .isRequired(),
          new Field()
            .setKey('group_name')
            .setTitle(__('company.components.checklists.add-checklist-dialog.form.fields.breadcrumb.group-name'))
            .setTranslatable(true)
            .setVisibleIf((values: any) => !! values.breadcrumb.group_checked)
            .setSize(FieldSizes.elevenTwelfth)
            .isRequired(),
        ]),
      new ObjectField()
        .setKey('details')
        .setTitle(__('company.components.checklists.add-checklist-dialog.form.fields.details.title'))
        .setChildren([
          new SearchableField()
            .setKey('responsible_uuid')
            .setTitle(__('company.components.checklists.add-checklist-dialog.form.fields.details.responsible'))
            .loadItems({ endpoint: `company/${ this.company.slug }/employees`, value: 'uuid', title: 'full_name', perPage: 20 })
            .isRequired(),
          new Field()
            .setKey('name')
            .setTitle(__('company.components.checklists.add-checklist-dialog.form.fields.details.name'))
            .setTranslatable(true)
            .isRequired(),
          new Field()
            .setType(FieldTypes.textArea)
            .setKey('description')
            .setTitle(__('company.components.checklists.add-checklist-dialog.form.fields.details.description'))
            .setTranslatable(true),
        ]),
      new ArrayField()
        .setTitle(__('company.components.checklists.add-checklist-dialog.form.fields.details.checkpoints'))
        .setKey('checkpoints')
        .setDense(true)
        .setFirstDelete(false)
        .setChildren([
          new Field()
            .setKey('name')
            .setTranslatable(true)
            .setTitle(__('company.components.checklists.add-checklist-dialog.form.fields.details.checkpoints-name'))
            .isRequired(),
        ]),
    ])
    .setInitialValues({
      checkpoints: [
        {
          name: {
            lt: '',
            no: ''
          }
        }
      ],
      breadcrumb: {
        // category_uuid: _.get(this.meta, 'parentUuids.0', ''),
        specialty_uuid: _.get(this.meta, 'parentUuids.0', ''),
        group_uuid: _.get(this.meta, 'parentUuids.1', ''),
      }
    })
    .setInjectValues({
      details: {
        project_uuid: this.meta.project.uuid,
      }
    })
    .setSubmit({
      text: __('company.components.checklists.add-checklist-dialog.form.submit')
    })
    .setValidate(true, { onlyRegionLangRequired: true })
    .setOnSuccess((response: IChecklistResponse, checklist: Checklist) => {
      this.$store.dispatch(GlobalActions.showSnackBar, {
        type: SnackBarTypes.success,
        message: __('company.components.checklists.add-checklist-dialog.form.on-success'),
      })

      this.meta.onSuccess && this.meta.onSuccess(checklist)
    })

  closeDialog(): void {
    this.$store.dispatch(GlobalActions.closeDialog)
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
