









































































import { Component, Inject, Vue, Watch } from 'vue-property-decorator'
import DocumentsSidebar from '@/company/components/documents/DocumentsSidebar.vue'
import Project from '@/shared/modules/project/models/project.model'
import Group from '@/shared/modules/group/group.model'
import permissions from '@/shared/helpers/permissions.helper'
import __ from '@/shared/helpers/__'
import can from '@/shared/helpers/can.helper'
import ChecklistSidebar from '@/company/components/checklists/ChecklistSidebar.vue'
import { Multipane, MultipaneResizer } from 'vue-multipane'
import ChecklistTemplates from '@/company/components/checklists/ChecklistTemplates.vue'
import ChecklistPreview from '@/company/components/checklists/ChecklistPreview.vue'
import Checklist from '@/shared/modules/checklist/checklist.model'
import GroupService from '@/shared/modules/group/group.service'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import { GlobalActions } from '@/shared/store/global/global.actions'
import AddChecklistDialog from '@/company/components/checklists/AddChecklistDialog.vue'
import DocumentsSidebarWrapper from '@/company/components/documents/DocumentsSidebarWrapper.vue'
import Can from '@/shared/components/Can.vue'
import Sticky from 'vue-sticky-directive'
import { GlobalGetters } from '@/shared/store/global/global.getters'

@Component({
  components: { Can, ChecklistPreview, ChecklistSidebar, DocumentsSidebar, Multipane, MultipaneResizer, ChecklistTemplates, DocumentsSidebarWrapper },
  methods: { __, can },
  directives: {
    sticky: Sticky
  }
})
export default class Checklists extends Vue {
  @Inject('project') getProject!: () => Project
  @Watch('activeGroup') private onActiveGroupChange(): void {
    if (this.activeGroup) {
      this.sidebarWrapper.active = []
    }
  }
  permissions = permissions
  checklist: Checklist | null = null
  activeGroup: Group | null = null
  templatesOpen: boolean = false
  templateDrag: boolean = false
  offset = {
    top: this.headersHeight + 10,
    bottom: 0,
  }

  dragStarted(group: any): void {
    if (this.templateDrag) {
      this.templateDrag = false
      return
    }
    const checklistTemplates: any = this.$refs.checklistTemplates
    if (checklistTemplates) checklistTemplates.dragstart(group, true)
  }

  templateDragStarted(group: any): void {
    this.templateDrag = true
    this.checklistSidebarRef.dragstart(group)
  }

  templateDragEnded(group: any): void {
    this.templateDrag = false
    this.checklistSidebarRef.dragend(group, true)
  }

  onChecklistUpdated(): void {
    if (! this.activeGroup) return
    this.checklistSidebarRef.loadGroups()
    this.loadChecklistByGroup(this.activeGroup)
  }

  onGroupActivated(group: Group): void {
    this.templatesOpen = false
    if (this.activeGroup && this.activeGroup.uuid === group.uuid) {
      this.activeGroup = null
      this.checklist = null
      return
    }
    this.activeGroup = null
    this.checklist = null

    this.activeGroup = group
    this.loadChecklistByGroup(group)
  }

  addChecklist(): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: AddChecklistDialog,
      persistent: true,
      maxWidth: 768,
      meta: {
        project: this.getProject(),
        onSuccess: (checklist: Checklist) => {
          this.$store.dispatch(GlobalActions.closeDialog)

          this.checklistSidebarRef.loadGroups()
        }
      }
    })
  }

  toggleTemplates(): void {
    this.templatesOpen = ! this.templatesOpen
  }

  private loadChecklistByGroup(group: Group): void {
    GroupService.checklist(this.company, this.getProject(), group)
      .then((checklist: Checklist) => this.checklist = checklist)
      .catch(() => {
        this.activeGroup = null
        this.checklist = null
      })
  }

  get sidebarWrapper(): any {
    return this.$refs.sidebarWrapper
  }

  get checklistSidebarRef(): any {
    return this.$refs.checklistSidebar
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }

  get headersHeight(): number {
    return this.$store.getters[GlobalGetters.getHeadersHeight]
  }
}
