




































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import DocumentsSidebarWrapper from '@/company/components/documents/DocumentsSidebarWrapper.vue'
import __ from '@/shared/helpers/__'
import permissions from '@/shared/helpers/permissions.helper'
import Group, { GroupLevel } from '@/shared/modules/group/group.model'
import GroupService from '@/shared/modules/group/group.service'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import Project from '@/shared/modules/project/models/project.model'
import can from '@/shared/helpers/can.helper'
import { DraggableTree } from 'vue-draggable-nested-tree'
import _ from 'lodash'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import EditGroupDialog from '@/company/components/documents/groups/EditGroupDialog.vue'
import AreYouSureDialog from '@/shared/components/dialogs/AreYouSureDialog.vue'
import Can from '@/shared/components/Can.vue'
import DownloadReport from '@/company/components/checklists/DownloadReport.vue'
import AddChecklistDialog from '@/company/components/checklists/AddChecklistDialog.vue'
import Checklist from '@/shared/modules/checklist/checklist.model'
import { GlobalGetters } from '@/shared/store/global/global.getters'

@Component({
  components: { DocumentsSidebarWrapper, DraggableTree, Can },
  methods: { __, can },
})
export default class ChecklistSidebar extends Vue {
  @Prop() project!: Project
  @Prop() activeGroup!: Group | null
  @Prop() templatesOpen!: boolean
  @Watch('search') private handleSearch(): void {
    const recursive = (item: any) => {
      if (item.name.toLowerCase().includes(this.search ? this.search.toLowerCase() : '')) return true
      item.children = item.children.filter(recursive)
      if (item.children.length > 0) return true
      return false
    }

    this.items = _.cloneDeep(this.originalItems).filter(recursive)
  }
  groupLevel = GroupLevel
  permissions = permissions
  search: string = ''
  items: any[] = []
  originalItems: any[] = []
  loading: boolean = false

  created() {
    this.loadGroups()
  }

  dragstart(group: any) {
    if (this.items.length === 0) return
    const recursive = (item: any) => {
      item.droppable = false
      if (group.level === item.allowedLevel) {
        item.droppable = true
      }

      item.children.forEach(recursive)
    }

    this.items.forEach(recursive)

    const rootNode = this.tree.store.rootData
    this.$set(rootNode, 'droppable', false)
    // if (group.level === GroupLevel.category) {
    if (group.level === GroupLevel.speciality) {
      this.$set(rootNode, 'droppable', true)
    }

    this.$emit('drag-start', group)
  }

  dragend(dragItem: Group, systemic: boolean = false) {
    if (! systemic && this.tree.dplh.parent.allowedLevel !== dragItem.level)
      return false

    setTimeout(this.dragAndDropHandler)
    return true
  }

  dragAndDropHandler(): void {
    this.loading = true
    const data: any = []

    const recursive = (group: Group, index: number, parentUuid: string | null = null) => {
      data.push({
        uuid: group.uuid,
        index,
        parent_uuid: parentUuid,
      })

      group.children.forEach((subGroup: Group, subIndex: number) => recursive(subGroup, subIndex, group.uuid))
    }

    this.items.forEach((group: Group, index: number) => recursive(group, index))
    if (data.length === 0) return
    this.dragAndDropCall(data)
  }

  async createChecklist(group: Group) {
    const parentUuids = await GroupService.parentUuids(this.company, this.project.uuid, group)

    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: AddChecklistDialog,
      persistent: true,
      maxWidth: 768,
      meta: {
        project: this.project,
        parentUuids,
        onSuccess: (checklist: Checklist) => {
          this.$store.dispatch(GlobalActions.closeDialog)
          this.loadGroups()
        }
      }
    })
  }

  editGroup(group: Group): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: EditGroupDialog,
      maxWidth: 600,
      meta: {
        group,
        project: this.project,
        onSuccess: () => {
          this.loadGroups()
          if (this.activeGroup === group) {
            this.$emit('on-group-activated', group)
            this.$emit('on-group-activated', group)
          }
        }
      }
    })
  }

  downloadReport(group: Group): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: DownloadReport,
      meta: {
        group,
      }
    })
  }

  deleteGroup(group: Group): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: __('company.components.views.projects.projects-show.documents-sidebar.delete-dialog.title'),
        text: __('company.components.views.projects.projects-show.documents-sidebar.delete-dialog.text', { name: group.name }),
        onYes: () => GroupService.destroy(this.company, this.project, group)
          .then(() => {
            if (this.activeGroup && this.activeGroup.uuid === group.uuid) {
              this.$emit('on-group-activated', group)
            }
            this.loadGroups()
          })
      }
    })
  }

  toggle(group: Group, store: any): void {
    store.toggleOpen(group)
    if (group.level === GroupLevel.checklist) {
      this.$emit('on-group-activated', group)
    }
  }

  public async loadGroups() {
    await GroupService.tree(this.company, this.project, 'checklists')
      .then((groups: Group[]) => {
        if (! this.tree) return

        const opened: Group[] = this.tree.store.getOpened()
        const recursive = (group: Group): boolean => {
          const found = opened.find((item: Group) => item.uuid === group.uuid)
          if (found) group.open = found.open
          group.children.forEach(recursive)

          if (this.activeGroup && group.uuid === this.activeGroup.uuid) return true
          return group.children.some(recursive)
        }

        const activeExists = groups.some(recursive)
        if (this.activeGroup && ! activeExists)
          this.$emit('on-group-activated', this.activeGroup)

        this.items = _.cloneDeep(groups)
        this.originalItems = _.cloneDeep(groups)
        this.loading = false
      })
  }

  private async dragAndDropCall(data: any[]): Promise<any> {
    await GroupService.dragAndDrop(this.company, this.project, data)
      .then(() => {
        this.$store.dispatch(GlobalActions.showSnackBar, {
          type: SnackBarTypes.success,
          message: __('company.components.checklists.checklist-sidebar.on-success'),
        })

        this.loadGroups()
      })
  }

  get tree(): any {
    return this.$refs.tree
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }

  get headersHeight(): number {
    return this.$store.getters[GlobalGetters.getHeadersHeight]
  }
}
