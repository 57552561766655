


































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import _ from 'lodash'
import { DraggableTree } from 'vue-draggable-nested-tree'
import Project from '@/shared/modules/project/models/project.model'
import Group, { GroupLevel } from '@/shared/modules/group/group.model'
import permissions from '@/shared/helpers/permissions.helper'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import SystemicGroupService from '@/shared/modules/group/systemic-group.service'
import __ from '@/shared/helpers/__'

@Component({
  components: { DraggableTree },
  methods: { __ }
})
export default class ChecklistTemplates extends Vue {
  @Prop() project!: Project
  @Watch('search') private handleSearch(): void {
    const recursive = (item: any) => {
      if (item.name.toLowerCase().includes(this.search ? this.search.toLowerCase() : '')) return true
      item.children = item.children.filter(recursive)
      if (item.children.length > 0) return true
      return false
    }

    this.items = _.cloneDeep(this.originalItems).filter(recursive)
  }
  search: string = ''
  groupLevel = GroupLevel
  permissions = permissions
  items: any[] = []
  originalItems: any[] = []

  created() {
    this.loadSystemicGroups()
  }

  makeDroppable(value: boolean = true): void {
    const recursive = (item: any) => {
      item.droppable = value

      item.children.forEach(recursive)
    }

    this.items.forEach(recursive)
    const rootNode = this.tree.store.rootData
    this.$set(rootNode, 'droppable', value)

  }

  dragstart(group: any, checklist: boolean = false) {
    if (checklist) {
      this.makeDroppable(false)
      return
    }
    this.makeDroppable(true)
    this.$emit('template-drag-start', { ...group })
  }

  dragend(group: any) {
    setTimeout(() => {
      if (this.tree._uid !== group._vm.store._uid) {
        this.$emit('template-drag-end', { ...group })
      }
      this.items = _.cloneDeep(this.originalItems)
    })
    return true
  }

  toggle(data: any, store: any, vm: any): void {
    const recursive = (item: any) => {
      if (item.uuid === data.uuid) item.open = ! data.open
      item.children.forEach(recursive)
    }

    this.originalItems.forEach(recursive)
    store.toggleOpen(data)
  }

  private async loadSystemicGroups() {
    await SystemicGroupService.index()
      .then((groups: Group[]) => {
        this.items = _.cloneDeep(groups)
        this.originalItems = _.cloneDeep(groups)
      })
  }

  get tree(): any {
    return this.$refs.templatesTree
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
